import MultiUploader from '@/components/MultiUploader.vue';
import { computed, defineComponent, ref, } from 'vue';
import DialogContainer from '@/components/DialogContainer.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'EditRequirement',
    props: {
        // 任务条件详情
        target: {
            type: Object,
            required: true,
        },
        // 默认的表单数据
        defaultForm: {
            type: Object,
            required: true,
        },
        // 任务条件序号 从0开始
        index: {
            type: Number,
            required: true,
        },
    },
    components: {
        MultiUploader,
        DialogContainer,
    },
    emits: {
        confirm: (val) => val,
        cancel: () => true,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const bindForm = ref(JSON.parse(JSON.stringify(props.defaultForm)));
        const isConfirmDisabled = computed(() => !bindForm.value.detail.every((item) => item.value.length > 0 && item.value.every((str) => str.length > 0)));
        const confirm = () => {
            emit('confirm', bindForm.value);
        };
        const cancel = () => {
            emit('cancel');
        };
        const inputHandler = (e, index) => {
            const target = e.target;
            bindForm.value.detail[index].value[0] = target.value;
        };
        const pictureChangeHandler = (val, index) => {
            bindForm.value.detail[index].value = val;
        };
        return {
            t,
            confirm,
            cancel,
            bindForm,
            inputHandler,
            pictureChangeHandler,
            isConfirmDisabled,
        };
    },
});
