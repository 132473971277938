import BaseButton from '@/components/BaseButton.vue';
import BaseTab from '@/components/BaseTab.vue';
import Toast from '@/components/Toast';
import EditTask from '@/core/Interactors/EditTask';
import Backend from '@/core/Services/Backend';
import StorageServices from '@/core/Services/StorageServices';
import useLangSelect from '@/utils/useLangSelect';
import useRequest from '@/utils/useRequest';
import DetailPage from '@/views/User/TaskDetail/DetailPage.vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import ButtonLayer from '@/components/ButtonLayer.vue';
/**
 * 获取预览默认语言
 * @desc 如果当前网站语言已经编辑则显示当前网站语言，否则显示第一个已经编辑的语言
 * @param lang 当前网页语言
 * @param filledTask 仅包含已经填写的语言表单的任务
 */
const getDefaultLang = (lang, filledTask) => {
    const target = filledTask.infos.find((item) => item.lang === lang);
    if (!target) {
        return filledTask.infos[0]?.lang || lang;
    }
    return lang;
};
export default defineComponent({
    name: 'PreviewTask',
    components: {
        BaseButton,
        DetailPage,
        BaseTab,
        ButtonLayer,
    },
    setup() {
        const { t, locale } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const task = StorageServices.getEditingTask();
        if (!task) {
            throw new Error('unexpected: task not found');
        }
        // 任务对象（排除完全未填写的语言）
        const filledTask = {
            ...task,
            infos: task.infos.filter((item) => !EditTask.getIsLangFormEmpty(item)),
        };
        const { selectedLang, languages } = useLangSelect(getDefaultLang(locale.value, filledTask));
        const { submit, pending } = useRequest();
        const isNextDiasbled = route.query.complete === '0';
        const nextHandler = () => {
            submit(async (cancel) => {
                const editId = route.query.id;
                if (editId) {
                    await Backend.editTask(filledTask, cancel);
                }
                else {
                    await Backend.submitTask(filledTask, cancel);
                }
                Toast.success(t('submit_task_success'));
                router.push('/inc');
            });
        };
        return {
            t,
            selectedLang,
            languages,
            task,
            nextHandler,
            pending,
            isNextDiasbled,
            filledTask,
        };
    },
});
