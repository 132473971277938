/**
 * @name useTimeCount
 * @author yuanzeyu
 * @date 2021/6/18
 * @desc
 */
import { useI18n } from 'vue-i18n';
import { TaskStatus } from '@/core/types/Task';
import formatTime from '@/core/utils/formatTime';
import { ref, computed, onMounted, onBeforeUnmount, } from 'vue';
const useTimeCount = (start, end, lang) => {
    const now = ref(Date.now());
    const timer = ref(0);
    const { t, locale } = useI18n();
    const status = computed(() => {
        if (now.value < start.value) {
            return TaskStatus.BeforeStart;
        }
        if (now.value < end.value) {
            return TaskStatus.Active;
        }
        return TaskStatus.End;
    });
    const isTaskActive = computed(() => status.value === TaskStatus.Active);
    const remainTime = computed(() => {
        if (status.value !== TaskStatus.Active) {
            return ''; // 暂定非进行中的，不显示
        }
        const remain = end.value - now.value;
        const min = 60 * 1000;
        const hour = min * 60;
        const day = hour * 24;
        const remainDay = Math.floor(remain / day);
        const remainHours = Math.floor((remain % day) / hour);
        const remainMin = Math.floor((remain % hour) / min);
        return t('remain_time', {
            days: remainDay,
            hours: remainHours,
            minutes: remainMin,
        }, {
            locale: lang ? lang.value : locale.value,
        });
    });
    const duration = computed(() => {
        if (!start.value) {
            return '';
        }
        const startTime = formatTime(start.value, '{y}-{m}-{d} {h}:{i}');
        const endTime = formatTime(end.value, '{y}-{m}-{d} {h}:{i}');
        return `${startTime}-${endTime}(UTC+8)`;
    });
    onMounted(() => {
        timer.value = window.setInterval(() => {
            now.value = Date.now();
        }, 1000);
    });
    onBeforeUnmount(() => {
        if (timer.value) {
            window.clearInterval(timer.value);
        }
    });
    return {
        status,
        remainTime,
        duration,
        isTaskActive,
    };
};
export default useTimeCount;
