import { defineComponent } from 'vue';
export default defineComponent({
    name: 'RequirementItem',
    props: {
        target: {
            type: Object,
            required: true,
        },
        index: {
            // 从0开始
            type: Number,
            required: true,
        },
        submitted: {
            // 已经提交过，显示已完成
            type: Boolean,
            required: true,
        },
        edited: {
            // 已经编辑过，可再次编辑
            type: Boolean,
            required: true,
        },
        disabled: {
            // 显示禁用状态去完成（预览/非进行中）
            type: Boolean,
            required: true,
        },
        t: {
            type: Function,
            required: true,
        },
    },
    emits: {
        goEdit: (target) => target,
    },
    setup(props, { emit }) {
        const clickHandler = () => {
            emit('goEdit', props.target);
        };
        return {
            clickHandler,
        };
    },
});
