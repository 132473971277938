import AvatarBox from '@/components/AvatarBox.vue';
import BaseUploader from '@/components/BaseUploader.vue';
import Backend from '@/core/Services/Backend';
import { readFile } from '@/core/utils';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
/**
 * 上传多张图片
 */
export default defineComponent({
    name: 'MultiUploader',
    components: {
        AvatarBox,
        BaseUploader,
    },
    props: {
        // 绑定字符串数组
        pictures: {
            type: Array,
            required: true,
        },
        limit: {
            type: Number,
            required: false,
        },
        autoUpload: {
            // 当前每次选择立即上传到服务器,true则绑定远程url
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: {
        change: (val) => val,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const uploadDisable = computed(() => Boolean(props.limit && props.pictures.length === props.limit));
        const addFile = async (file) => {
            const base64 = await readFile(file);
            if (props.autoUpload) {
                const fileUrl = await Backend.uploadPicture(base64, file.name);
                emit('change', [...props.pictures, fileUrl]);
            }
            else {
                emit('change', [...props.pictures, base64]);
            }
        };
        const deletePicture = (index) => {
            const update = [...props.pictures];
            update.splice(index, 1);
            emit('change', update);
        };
        return {
            addFile,
            t,
            deletePicture,
            uploadDisable,
        };
    },
});
