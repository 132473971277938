import useDialog from '@/utils/useDialog';
import { ref, computed } from 'vue';
/**
 * 任务详情页编辑条件控制器
 */
const useEditRequirement = (defaultTaskForm) => {
    // 任务条件表单
    const bindForm = ref(defaultTaskForm);
    // 已经提交过,当前仅支持提交一次
    const isSubmitted = Boolean(bindForm.value && bindForm.value.address.length > 0);
    // 编辑弹窗
    const { isVisible, showDialog, closeDialog, selectedTarget, selectedIndex, } = useDialog();
    // 点击查看过的条件id
    const viewedIdList = ref([]);
    // 任务条件表单已经填写完成
    const isFilled = computed(() => {
        if (!bindForm.value) {
            return false;
        }
        const emptyField = bindForm.value.conditionInfo.find((condition) => {
            if (condition.detail.length === 0) {
                return viewedIdList.value.indexOf(condition.id) < 0; // 不需要填写的，点击查看后即算作已完成
            }
            return condition.detail.find((detail) => detail.value.length === 0 || detail.value[0].length === 0);
        });
        return !emptyField;
    });
    // 获取当前条件是否已经编辑过
    const getIsEdited = (index) => {
        if (!bindForm.value) {
            return false;
        }
        const condition = bindForm.value.conditionInfo[index];
        const { detail } = condition;
        if (detail.length === 0) {
            // 条件可以没有输入表单
            return viewedIdList.value.indexOf(condition.id) > -1; // 不需要填写的，点击查看后即算作已完成
        }
        const flagValue = detail[0].value;
        return flagValue.length > 0 && flagValue[0].length > 0;
    };
    // 点击去完成事件处理
    const goEditHandler = (target, index) => {
        const { id } = target;
        if (viewedIdList.value.indexOf(id) < 0) {
            viewedIdList.value.push(id);
        }
        showDialog(target, index);
    };
    return {
        bindForm,
        isSubmitted,
        isVisible,
        showDialog,
        closeDialog,
        selectedTarget,
        selectedIndex,
        goEditHandler,
        getIsEdited,
        isFilled,
    };
};
export default useEditRequirement;
