import DialogContainer from '@/components/DialogContainer.vue';
import InputField from '@/components/InputField.vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { validateEthLikeAddress } from '@/core/utils/validate';
import Toast from '@/components/Toast';
export default defineComponent({
    name: 'EditAddress',
    components: {
        InputField,
        DialogContainer,
    },
    props: {
        defaultAddress: {
            type: String,
            required: true,
        },
        chainLabel: {
            type: String,
            requried: true,
        },
    },
    emits: ['cancel', 'confirm'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const bindValue = ref(props.defaultAddress);
        const emitCancel = () => {
            emit('cancel');
        };
        const confirmHandler = () => {
            if (!validateEthLikeAddress(bindValue.value)) {
                Toast.error(t('enter_valid_address'));
                return;
            }
            emit('confirm', bindValue.value);
        };
        return {
            t,
            bindValue,
            emitCancel,
            confirmHandler,
        };
    },
});
