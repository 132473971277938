import { Lang } from '@/core/types/enums';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    setup() {
        const { locale } = useI18n();
        const isCn = computed(() => locale.value === Lang.zh);
        return {
            isCn,
        };
    },
});
