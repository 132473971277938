import AvatarBox from '@/components/AvatarBox.vue';
import Backend from '@/core/Services/Backend';
import useRequest from '@/utils/useRequest';
import EditAddress from '@/views/User/TaskDetail/EditAddress.vue';
import EditRequirement from '@/views/User/UserHome/EditRequirement.vue';
import useTimeCount from '@/views/User/UserHome/useTimeCount';
import { defineComponent, computed } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import useTask from '@/utils/useTask';
import useDialog from '@/utils/useDialog';
import Dialog from '@/components/Dialog.vue';
import { useI18n } from 'vue-i18n';
import Toast from '@/components/Toast';
import { useRouter } from 'vue-router';
import EmptyBanner from '@/components/EmptyBanner.vue';
import RequirementItem from './RequirementItem.vue';
import useEditRequirement from './useEditRequirement';
export default defineComponent({
    name: 'DetailPage',
    components: {
        EditAddress,
        EditRequirement,
        AvatarBox,
        BaseButton,
        RequirementItem,
        Dialog,
        EmptyBanner,
    },
    props: {
        task: {
            type: Object,
            required: true,
        },
        taskForm: {
            // 预览时不需要
            type: Object,
            required: false,
        },
        preview: {
            type: Boolean,
            required: false,
            default: false,
        },
        lang: {
            // 指定语言
            type: String,
            required: false,
        },
    },
    setup(props) {
        const router = useRouter();
        const { t: translate, locale } = useI18n();
        const usedLang = computed(() => props.lang || locale.value);
        /**
         * 使用指定的语言显示详情（用与预览）
         */
        const t = computed(() => (val) => translate(val, 0, {
            locale: usedLang.value,
        }));
        /**
         * 任务详情
         */
        const taskRef = computed(() => props.task);
        const customLang = computed(() => props.lang);
        const { taskInfo } = useTask(taskRef, customLang);
        /**
         * 时间相关
         */
        const start = computed(() => props.task.startTime || 0);
        const end = computed(() => props.task.endTime || 0);
        const { remainTime, duration, isTaskActive } = useTimeCount(start, end, usedLang);
        /**
         * 编辑绑定的表单
         */
        const { bindForm, isSubmitted, isVisible, showDialog, closeDialog, selectedTarget, selectedIndex, goEditHandler, getIsEdited, isFilled, } = useEditRequirement(props.taskForm || null);
        const confirmHandler = (form) => {
            if (!bindForm.value) {
                return;
            }
            bindForm.value.conditionInfo[selectedIndex.value] = form;
            closeDialog();
        };
        /**
         * 提交表单控制器
         */
        const { isVisible: isAddressVisible, showDialog: showAddressDialog, closeDialog: closeAddressDialog } = useDialog();
        const submitDisableReason = computed(() => {
            const form = bindForm.value;
            if (!form) {
                // 预览或未查询出参与信息:显示禁用
                return 'preview';
            }
            if (isSubmitted) {
                return 'submitted';
            }
            return isFilled.value ? '' : 'requirement_not_filled';
        });
        const submitLabel = computed(() => {
            if (submitDisableReason.value === 'submitted') {
                return t.value('apply_has_submitted');
            }
            if (submitDisableReason.value === 'requirement_not_filled') {
                return t.value('requirement_not_filled');
            }
            return t.value('apply_airdrop');
        });
        const { pending: isSubmitPending, submit } = useRequest();
        const confirmSubmit = (address) => {
            if (!bindForm.value) {
                return;
            }
            bindForm.value.address = address;
            submit(async (cancel) => {
                if (!bindForm.value) {
                    return;
                }
                await Backend.submitUserApplyTask(bindForm.value, cancel);
                Toast.success(t.value('submit_success'));
                closeAddressDialog();
                router.go(-1);
            });
        };
        return {
            t,
            remainTime,
            duration,
            taskInfo,
            isVisible,
            showDialog,
            closeDialog,
            selectedTarget,
            selectedIndex,
            goEditHandler,
            confirmHandler,
            bindForm,
            isAddressVisible,
            showAddressDialog,
            closeAddressDialog,
            confirmSubmit,
            isSubmitPending,
            submitDisableReason,
            isSubmitted,
            getIsEdited,
            submitLabel,
            isTaskActive,
        };
    },
});
